import Image from 'next/image'

const ProductImage = ({
  src,
  alt = '',
  height,
  width,
  className,
  loading = 'lazy',
  quality = 90,
  priority = false,
  unoptimized = false,
  sizes = null,
}) => {
  const loader = ({ src = '', width }) => {
    let customSrc
    let urlPartials = src.split('original')
    if (urlPartials[1]) {
      customSrc = `${urlPartials[0]}${customWidth}w${urlPartials[1]}`
    } else {
      customSrc = src
    }
    return `${process.env.NEXT_PUBLIC_BASE_URL}/_next/image?url=${customSrc}&w=${width}&q=${quality}&fm=webp`
  }

  let imageProps = {
    unoptimized: unoptimized,
    loader: loader,
    className: className,
    src: src,
    alt: alt,
    fill: height || width ? false : true,
    quality: quality,
    height: height,
    width: width,
  }

  if (priority) {
    imageProps['priority'] = true
  } else {
    imageProps['loading'] = loading
  }

  if (sizes) {
    imageProps['sizes'] = sizes
  }

  return <Image {...imageProps} />
}

export default ProductImage
