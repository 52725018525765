import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as types from '@/redux/actionTypes'
import { getActivityLabel, displayColorSwatch } from '@/lib/product-helper'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import styleConfig from '@/styles/style-config'
import { styled } from 'stitches.config'
import { selectItemGTM } from '@/lib/gtm'
import { Image } from '@/components/image'
import useIntersection from '@/hooks/useIntersection'
import { isEmpty } from '@/lib/lodash'
import ProductImage from '@/components/image/ProductImage'

const ProductListCard = dynamic(() => import('@/components/product/product-list-card'))
const ProductDisplayPrice = dynamic(() => import('@/components/product/product-display-price'))
const ProductDiscountBadge = dynamic(() => import('@/components/product/product-discount-badge'))
const ReviewStarsWidget = dynamic(() => import('@/components/review/review-stars-widget'))

const ProductBoxImage = styled(ProductImage, {
  '@xs': { height: '250px', width: '250px' },
  '@md': { height: '293px', width: '293px' },
  '@lg': { height: '251px', width: '251px' },
  '@xl': { height: '388px', width: '388px' },
})

const ProductUpCard = (props) => {
  const { isAuthenticated, membership } = useSelector((state) => state.userReducer)
  const { customerGroup } = membership
  const dispatch = useDispatch()
  const productRef = useRef(null)
  const reviewsRef = useRef(null)
  let useThisProduct = props.product[0] ? props.product[0] : props.product
  const [currentProduct, setCurrentProduct] = useState(useThisProduct)
  const activityLabel = getActivityLabel(useThisProduct.custom_fields)
  const productUrl = useThisProduct.slug
  const reviews = useThisProduct.reviewsCount
  const primaryImage = useThisProduct.selectedVariant.imageUrl
  const cardImageWidth = {
    xs: 250,
    md: 293,
    lg: 251,
    xl: 388,
  }

  const setSelectedVariant = async (label, product) => {
    let defaultSize = product.options
      .find((opt) => opt.display_name == 'Size')
      .option_values.find((opt) => opt.is_default == true)
    const variant = product.groupedVariants[label]
    if (typeof variant !== 'undefined') {
      let vId = null
      let size = null
      let variantObj = null
      if (defaultSize) {
        let sizeVariant = variant.find((item) => item.size == defaultSize.label)
        if (sizeVariant && sizeVariant.inventory_level > 0) {
          vId = sizeVariant.id
          size = sizeVariant.size
          variantObj = sizeVariant
        }
      }

      if (vId == null) {
        for (let i = 0; i < variant.length; i++) {
          if (variant[i].inventory_level > 0) {
            vId = variant[i].id
            size = variant[i].size
            variantObj = variant[i]
            break
          }
        }
      }

      if (vId == null) {
        vId = variant[0].id
        size = variant[0].size
        variantObj = variant[0]
      }

      const newProduct = {
        ...currentProduct,
        selectedVariant: {
          price: variantObj.price,
          salePrice: variantObj.sale_price,
          imageUrl: variantObj.image_url,
          hoverImage: variantObj.hoverImage,
          color: label,
          size: size,
          vId: vId,
        },
      }

      setCurrentProduct(newProduct)
    }
  }

  const getProductImageSize = () => {
    const newHeight = productRef.current && productRef.current.clientHeight
    props.setHeight && props.setHeight(newHeight)
  }

  // Update 'height' when the window resizes
  useEffect(() => {
    window.addEventListener('resize', getProductImageSize)

    return () => {
      window.removeEventListener('resize', getProductImageSize)
    }
  }, [])

  const handleMouseOver = () => {
    if (
      reviewsRef &&
      reviewsRef.current != null &&
      reviewsRef.current.classList.contains('hidden')
    ) {
      reviewsRef.current.classList.remove('hidden')
      reviewsRef.current.classList.add('flex')
    }
  }

  const handleMouseOut = () => {
    if (reviewsRef && reviewsRef.current != null && reviewsRef.current.classList.contains('flex')) {
      reviewsRef.current.classList.remove('flex')
      reviewsRef.current.classList.add('hidden')
    }
  }

  const handleProductClick = async () => {
    await selectItemGTM(currentProduct)
    dispatch({
      type: types.PRODUCT_OPENED,
      payload: {
        openProductId: currentProduct.id,
      },
    })
  }

  //GTM - altered root margin to accomodate slider view
  const inViewport = useIntersection(productRef, '-100px') // Trigger as soon as the element becomes visible

  useEffect(() => {
    if (inViewport && props?.setProductsViewed) {
      props.setProductsViewed((prevProductsViewed) => [...prevProductsViewed, currentProduct])
    }
  }, [inViewport])

  //

  return (
    <>
      <div className={props.type === 'product-list' ? '' : 'h-full'}>
        {props.type === 'product-list' ? (
          <ProductListCard
            currentProduct={currentProduct}
            activityLabel={activityLabel}
            isAuthenticated={isAuthenticated}
            customerGroup={customerGroup}
          />
        ) : (
          <div className="product-up-card product h-full">
            <div ref={productRef}>
              <Link
                className="cursor-pointer"
                href={
                  currentProduct.selectedVariant.vId == null
                    ? `/${productUrl}`
                    : `/${productUrl}?variant=${currentProduct.selectedVariant.vId}`
                }
              >
                <div
                  gtm-prod-id={currentProduct.id}
                  onClick={handleProductClick}
                  className="product-image-container relative"
                >
                  <div className={`absolute inset-0 overflow-hidden bg-gray-200`}>
                    {currentProduct != null && (
                      <ProductDiscountBadge
                        productType={props.type}
                        isAuthenticated={isAuthenticated}
                        product={currentProduct}
                        customerGroup={customerGroup}
                        type="productUpCard"
                      />
                    )}
                    {currentProduct != null && !isEmpty(currentProduct.selectedVariant) && (
                      <div
                        className={`product-box relative flex items-center justify-center w-full h-full group lg:hover:opacity-50`}
                        object="contain"
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                      >
                        <ProductBoxImage
                          className={`w-full product-image normal-product object-contain`}
                          src={currentProduct.selectedVariant.imageUrl}
                          alt={useThisProduct.name}
                          sizes={`(min-width: ${styleConfig.theme.screens.sm}) ${cardImageWidth.md}px,
                                  (min-width: ${styleConfig.theme.screens.md}) ${cardImageWidth.lg}px,
                                  (min-width: ${styleConfig.theme.screens.lg}) ${cardImageWidth.xl}px, ${cardImageWidth.xs}px`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            </div>

            <div className="product-up-card__details product-detail px-1">
              <p className="product-up-card__category category-label font-primary uppercase text-gray-600 mb-2.5 flex items-center">
                {activityLabel.sportActivity && activityLabel.sportActivity}
                {activityLabel.gender && <span className="px-1.5">&middot;</span>}
                {activityLabel.gender && activityLabel.gender}
              </p>
              <p
                className={`product-up-card__name ${
                  props.type !== 'recommendation-product'
                    ? 'slider-product-name'
                    : 'recommended-product-name'
                } font-primary uppercase font-normal text-base sm:text-smd xl:text-md text-black`}
              >
                {useThisProduct.name}
              </p>
              <div
                className={`relative flex flex-col md:flex-row justify-between ${
                  props.type !== 'recommendation-product' ? 'product-slider' : 'product-recommended'
                }`}
              >
                <div className="product-up-card__price">
                  {currentProduct != null && (
                    <ProductDisplayPrice
                      isAuthenticated={isAuthenticated}
                      product={currentProduct}
                      customerGroup={customerGroup}
                    />
                  )}
                </div>
                {currentProduct != null && currentProduct?.availableColors?.length != 0 && (
                  <div className="product-up-card__colors flex mt-2 md:mt-0">
                    {displayColorSwatch(
                      currentProduct,
                      setSelectedVariant,
                      currentProduct?.availableColors
                    )}
                  </div>
                )}
                {currentProduct != null && reviews && reviews.score > 0 && (
                  <div
                    ref={reviewsRef}
                    className="relative reviewing-container hidden w-full justify-center items-center border-t border-newGray bg-white"
                  >
                    <div className="product-up-card__reviews">
                      <ReviewStarsWidget product={{ id: currentProduct?.id }} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <style jsx>
        {`
      .review-p-t {
        padding-top: 3px;
      }

      .margin-b-10 {
        margin-bottom: 10px;
      }

      .product-up-card {
        display: flex;
        flex-direction: column;
      }

      .product-up-card__category {
        font-size: 13px;
        line-height: 17px;
      }

      .product-up-card__details {
        padding-top: 20px;
      }

      .product-up-card__price {
        padding-top: 2px;
      }

      .product-up-card__colors {
        flex-wrap: wrap;
      }

      .reviewing-container {
        position: absolute;
        bottom: -57px;
        margin: 0;
        padding:10px 0 10px;
      }

      .product .badge {
        top: 10px;
        left: 10px;
        padding: 2px 10px;
        display: flex;
      }

      .product .product-badge {
        background-color: #424832;
      }

      .product-box img.normal-product {
        transform: scale(1);
        transition: all ease-in 0.25s;
      }

      .product-box img.hover-product {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transform: scale(1.25);
        transition: all ease-in 0.25s;
      }

      .product-box:hover img.normal-product.with-alt {
        opacity: 0;
        transform: scale(0.75);
        transition: all ease-in 0.25s;
      }

      .product-box:hover img.hover-product {
        opacity: 1;
        transition: all ease-in 0.25s;
        transform: scale(1);
      }

      .category-label {
        line-height: 17px;
      }

      .recommended-product-name, .slider-product-name {
        line-height: 14px;
      }

      .product-up-card__name {
        height: 28px;
        margin-bottom: 15px;
      }

      .product-image-container {
        padding-top: ${100 / (243 / 332)}%;
      }

      .product-list__item {
        width: 270px;
      }

      .product-list__img-wrap {
        height: 80px;
        width: 80px;
      }

      @media (min-width: ${styleConfig.theme.screens.md}) {
        .category-label {
          line-height: 18px;
        }

        .product-up-card__category {
          font-size: 14px;
        }

        .product-recommended {
          margin-top: 22px;
        }

        .product-image-container {
          padding-top: ${100 / (294 / 401)}%;
        }

        .recommended-product-name {
          line-height: 14px;
        }

        .slider-product-name {
          line-height: 16px;
        }

        .product-up-card__name {
          height: 32px;
        }

        .product-up-card__colors {
          padding-left: 30px;
        }

        .product-list__item {
          width: 300px;
        }
      }

      @media (min-width: ${styleConfig.theme.screens.lg}) {
        .product-recommended {
          margin-top: 20px;
        }

        .product-image-container {
          padding-top: ${100 / (241 / 329)}%;
        }

        .recommended-product-name, .slider-product-name {
          line-height: 16px;
        }

        .product-up-card__name {
          margin-bottom: 18px;
        }

        .product-list__item {
          width: 310px;
        }

        .product-list__img-wrap {
          height: 88px;
          width: 88px;
        }
      }
      @media (min-width: ${styleConfig.theme.screens.xl}) {
        .product-image-container {
          padding-top: ${100 / (372 / 508)}%;
        }


        .recommended-product-name, .slider-product-name {
          line-height: 18px;
        }

        .product-up-card__name {
          height: 36px;
          margin-bottom: 18px;
        }

        .product-list__item {
          width: 320px;
        }

        .product-list__img-wrap {
          height: 97px;
          width: 97px;
        }
      `}
      </style>
    </>
  )
}

export default ProductUpCard
